import React from "react";

import styled from "@emotion/styled";

import dinImage from "./din_pellets.svg";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const DinLogo = () => {
    return (
        <Wrapper>
            <img src={dinImage} alt="Din Logo" />
        </Wrapper>
    );
};

export default DinLogo;
