import React, { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../../providers/PelletsDataProvider";
import CalculatorProducts from "../../../../shared/CalculatorProducts";
import Margin from "../../../../shared/Margin";
import BagsProduct from "./BagsProduct";
import BigBagsProduct from "./BigBagsProduct";
import LooseProduct from "./LooseProduct";

const CalculatorResult = () => {
    const dataContext = useContext(DataContext);

    const { result, product, productChoice, additions, loadingError } =
        dataContext.data;
    const { setProductChoice } = dataContext.actions;
    const [expanded, setExpanded] = useState([]);

    const isExpanded = (itemIndex) => {
        return expanded.includes(itemIndex);
    };

    useEffect(() => {
        setExpanded([]);
    }, [result]);

    const toggleExpand = (itemIndex) => {
        if (isExpanded(itemIndex)) {
            setExpanded(expanded.filter((item) => item !== itemIndex));
        } else {
            setExpanded([...expanded, itemIndex]);
        }
    };

    const subline =
        `Preis pro ${
            product === "loose"
                ? "1.000 kg"
                : product === "bags"
                ? "Palette"
                : "Big-Bag"
        } inkl. MwSt.` +
        (additions && product === "loose"
            ? `, zzgl. ${additions.blow_in_add} € Frachtkosten`
            : "");

    if (result !== null && !loadingError) {
        return (
            <>
                <div>
                    <CalculatorProducts
                        products={result}
                        activeIndex={productChoice}
                        onIndexChange={setProductChoice}
                        subline={subline}
                    />
                    {product === "loose" && (
                        <LooseProduct
                            isExpanded={(itemIndex) => isExpanded(itemIndex)}
                            onExpand={(itemIndex) => toggleExpand(itemIndex)}
                        />
                    )}
                    {product === "bags" && (
                        <BagsProduct
                            isExpanded={(itemIndex) => isExpanded(itemIndex)}
                            onExpand={(itemIndex) => toggleExpand(itemIndex)}
                        />
                    )}
                    {product === "big-bag" && (
                        <BigBagsProduct
                            isExpanded={(itemIndex) => isExpanded(itemIndex)}
                            onExpand={(itemIndex) => toggleExpand(itemIndex)}
                        />
                    )}
                </div>
            </>
        );
    } else {
        return (
            <>
                {loadingError && (
                    <Margin bottom="20">
                        Daten konnten nicht geladen werden. Überprüfen Sie bitte
                        Ihre Eingaben.
                    </Margin>
                )}
            </>
        );
    }
};

export default CalculatorResult;
