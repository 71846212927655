import React from "react";

import { graphql } from "gatsby";

import CalculatorPage from "../../components/pages/Pellets/CalculatorPage";
import Page from "../../components/shared/Page";
import PelletsDataProvider from "../../providers/PelletsDataProvider";
import Error404Page from "../404";

const HolzpelletsProductPage = ({ data: { page }, params: { product } }) =>
    product == null ? (
        <Error404Page />
    ) : (
        <Page>
            <PelletsDataProvider>
                <CalculatorPage pageData={page} />
            </PelletsDataProvider>
        </Page>
    );
export default HolzpelletsProductPage;

export const query = graphql`
    query {
        page: pagesYaml(templateKey: { eq: "pages/holzpellets" }) {
            ...Page
            elements {
                ...EntireElement
            }
        }
    }
`;
