import React, { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../../providers/PelletsDataProvider";
import CalculatorHeader from "../../../shared/CalculatorHeader";
import CalculatorInactiveText from "../../../shared/CalculatorInactiveText";
import Container from "../../../shared/Container";
import Calculator from "./Calculator";
import CalculatorResult from "./CalculatorResult";

const CalculatorPage = ({ isWebview, isActive = true, inactiveText = "" }) => {
    const [calculatorVisible, setCalculatorVisible] = useState(true);
    const dataContext = useContext(DataContext);
    const { result, loading } = dataContext.data;

    useEffect(() => {
        if (!loading && result) {
            if (result.length === 0) {
                setCalculatorVisible(true);
            } else {
                setCalculatorVisible(false);
            }
        }
    }, [loading, result]);

    return isActive ? (
        <Container>
            <CalculatorHeader
                isWebview={isWebview}
                headline="Pellets"
                hideChangeData={calculatorVisible}
                onClickChangeData={() => setCalculatorVisible(true)}
            />
            {calculatorVisible && <Calculator />}
            <CalculatorResult />
        </Container>
    ) : (
        inactiveText && (
            <CalculatorInactiveText>{inactiveText}</CalculatorInactiveText>
        )
    );
};

export default CalculatorPage;
