import React, { useContext } from "react";

import checkoutSteps from "../../../../../../providers/PelCheckoutSteps";
import { DataContext } from "../../../../../../providers/PelletsDataProvider";
import resolveWebView from "../../../../../../utils/webViewRedirect";
import CalculatorProductDetails, {
    adjustButtonMargin,
    ExpandedContent,
    isTotal,
    PriceLabel,
    PriceValue,
    ProductDetails,
    ProductRow,
    ProductRowLabel,
    ProductRowValue,
    ProductRowValueAddition,
    ProductRowValueOnly,
    ToggleMore,
} from "../../../../../shared/CalculatorProductDetails";
import { navigate } from "../../../../../shared/LinkComponent";
import Button from "../../../../../shared/NewForms/Button";
import DinLogo from "../DinLogo";

const BagsProduct = ({ isExpanded, onExpand }) => {
    const dataContext = useContext(DataContext);
    const { result, amount, productChoice } = dataContext.data;
    const { syncProductInfos, syncCalculatorInput, clearCheckout } =
        dataContext.actions;

    return (
        <CalculatorProductDetails isTwo activeIndex={productChoice}>
            {result.map((product, idx) => {
                const itemIsExpanded = isExpanded(idx);

                return (
                    <ProductDetails key={idx}>
                        <ProductRow>
                            <ProductRowLabel>Menge:</ProductRowLabel>
                            <ProductRowValue>
                                {amount}&nbsp;Palette
                                {amount > 1 && "n"}
                                <br />
                                {product.bags_total} Säcke
                            </ProductRowValue>
                        </ProductRow>
                        <ProductRow>
                            <ProductRowLabel>Lieferzeit:</ProductRowLabel>
                            <ProductRowValue>
                                max. {product.delivery_time_days} Werktage{" "}
                                <ProductRowValueAddition>
                                    (Mo-Fr)
                                </ProductRowValueAddition>
                            </ProductRowValue>
                        </ProductRow>
                        <ProductRow>
                            <ProductRowLabel>Gewicht:</ProductRowLabel>
                            <ProductRowValue>
                                {product.weight}kg
                            </ProductRowValue>
                        </ProductRow>
                        <ProductRow>
                            <ProductRowLabel>Qualität:</ProductRowLabel>
                            <ProductRowValue>Premium-Pellets</ProductRowValue>
                        </ProductRow>
                        <ProductRow>
                            <PriceLabel css={isTotal}>Gesamt:</PriceLabel>
                            <PriceValue css={isTotal}>
                                {product.prices.priceTotalGross}&nbsp;&euro;
                            </PriceValue>
                        </ProductRow>
                        <Button
                            color="red"
                            css={adjustButtonMargin}
                            fullWidth
                            onClick={() => {
                                clearCheckout();
                                syncProductInfos(product);
                                syncCalculatorInput(product.id);
                                setTimeout(
                                    () =>
                                        navigate(
                                            resolveWebView(
                                                "/holzpellets/bestellung/" +
                                                    checkoutSteps[0].url
                                            )
                                        ),
                                    0
                                );
                            }}
                        >
                            Bestellen
                        </Button>

                        <ToggleMore onClick={() => onExpand && onExpand(idx)}>
                            {itemIsExpanded
                                ? "Weniger Details -"
                                : "Mehr Details +"}
                        </ToggleMore>

                        {itemIsExpanded && (
                            <ExpandedContent>
                                <ProductRowValueOnly>
                                    inkl.{" "}
                                    <strong>
                                        {product.cargo_add_gross}&nbsp;&euro;
                                    </strong>{" "}
                                    Lieferpauschale pro Palette
                                </ProductRowValueOnly>
                                <ProductRow>
                                    <ProductRowLabel>
                                        Säcke pro Palette:
                                    </ProductRowLabel>
                                    <ProductRowValue>
                                        {product.bags_per_pallet}
                                    </ProductRowValue>
                                </ProductRow>
                                <DinLogo />
                            </ExpandedContent>
                        )}
                    </ProductDetails>
                );
            })}
        </CalculatorProductDetails>
    );
};

export default BagsProduct;
