import React, { useContext } from "react";

import checkoutSteps from "../../../../../../providers/PelCheckoutSteps";
import { DataContext } from "../../../../../../providers/PelletsDataProvider";
import { formatNumber } from "../../../../../../utils/NumberHelpers";
import resolveWebView from "../../../../../../utils/webViewRedirect";
import CalculatorProductDetails, {
    adjustButtonMargin,
    ExpandedContent,
    isTotal,
    PriceLabel,
    PriceValue,
    ProductDetails,
    ProductRow,
    ProductRowLabel,
    ProductRowValue,
    ProductRowValueAddition,
    ToggleMore,
} from "../../../../../shared/CalculatorProductDetails";
import { navigate } from "../../../../../shared/LinkComponent";
import Button from "../../../../../shared/NewForms/Button";
import DinLogo from "../DinLogo";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const LooseProduct = ({ isExpanded, onExpand = () => {} }) => {
    const dataContext = useContext(DataContext);
    const { result, additions, amount, productChoice } = dataContext.data;
    const { syncProductInfos, syncCalculatorInput, clearCheckout } =
        dataContext.actions;

    return (
        <CalculatorProductDetails isTwo activeIndex={productChoice}>
            {additions &&
                result.map((product, idx) => {
                    const itemIsExpanded = isExpanded(idx);

                    return (
                        <ProductDetails key={idx}>
                            <ProductRow>
                                <ProductRowLabel>Menge:</ProductRowLabel>
                                <ProductRowValue>
                                    {formatNumber(amount)}&nbsp;kg
                                </ProductRowValue>
                            </ProductRow>
                            <ProductRow>
                                <ProductRowLabel>Lieferzeit:</ProductRowLabel>
                                <ProductRowValue>
                                    max. {product.delivery_time_days} Werktage{" "}
                                    <ProductRowValueAddition>
                                        (Mo-Fr)
                                    </ProductRowValueAddition>
                                </ProductRowValue>
                            </ProductRow>
                            <ProductRow>
                                <ProductRowLabel>Qualität:</ProductRowLabel>
                                <ProductRowValue>
                                    Premium-Pellets
                                </ProductRowValue>
                            </ProductRow>
                            <ProductRow>
                                <ProductRowLabel>Frachtkosten:</ProductRowLabel>
                                <ProductRowValue>
                                    {additions.blow_in_add}&nbsp;&euro; inkl.
                                    MwSt.
                                </ProductRowValue>
                            </ProductRow>
                            <ProductRow>
                                <PriceLabel css={isTotal}>Gesamt:</PriceLabel>
                                <PriceValue css={isTotal}>
                                    {product.prices.priceTotalGross}&nbsp;&euro;
                                </PriceValue>
                            </ProductRow>
                            <Button
                                color="red"
                                css={adjustButtonMargin}
                                fullWidth
                                onClick={() => {
                                    clearCheckout();
                                    syncProductInfos(product);
                                    syncCalculatorInput(product.id);
                                    setTimeout(
                                        () =>
                                            navigate(
                                                resolveWebView(
                                                    "/holzpellets/bestellung/" +
                                                        checkoutSteps[0].url
                                                )
                                            ),
                                        0
                                    );
                                }}
                            >
                                Bestellen
                            </Button>

                            <ToggleMore onClick={() => onExpand(idx)}>
                                {itemIsExpanded
                                    ? "Weniger Details -"
                                    : "Mehr Details +"}
                            </ToggleMore>

                            {itemIsExpanded && (
                                <ExpandedContent>
                                    <DinLogo />
                                </ExpandedContent>
                            )}
                        </ProductDetails>
                    );
                })}
        </CalculatorProductDetails>
    );
};

export default LooseProduct;
